<template>
    <div id="pdf-print-ready">

        <v-toolbar color="primary" dark>
            <v-toolbar-title>
                <v-icon large left>mdi-home</v-icon>
                {{ $t("rental-reference-form.title") }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <LocaleSwitcher/>
        </v-toolbar>

        <v-card-text v-if="is_complete">
            <v-alert text>{{ $t("rental-reference-form.reference_complete") }}</v-alert>
        </v-card-text>

        <div v-else>
            <v-card outlined>
                <v-card-text>
                    <v-row dense>
                        <v-col>
                            <p class="subtitle-1">
                                {{$t("common.to")}}: {{contact_name}}
                            </p>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col>
                            <p class="subtitle-1">
                                {{$t("rental-reference-form.contact_msg")}}
                            </p>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="2">
                            <p class="subtitle-1 font-weight-bold">
                                {{applicant_name}}
                            </p>
                        </v-col>
                        <v-col cols="10">
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col>
                            <p class="subtitle-1 font-weight-bold">
                                DOB: {{applicant_birthdate}}
                                <br/>
                                {{$t("common.rent_amt")}}: {{"$" + listed_rent_amount}}
                                <br/>
                                {{$t("common.move_in")}}: {{listed_move_in}}
                                <br/>
                                {{$t("common.move_out")}}: {{listed_move_out}}
                            </p>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card-text class="pt-14">
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row dense>
                        <v-col cols="12" md="4">
                            <v-subheader class="subtitle-1">{{
                                $t("rental-reference-form.listed_rent_amount", { listed_rent_amount })
                            }}</v-subheader>
                        </v-col>
                        <v-col cols="11" offset="1" md="8" offset-md="0">
                            <v-currency-field
                                prefix="$"
                                autocomplete="off"
                                type="number"
                                v-model="form.rent_amount"
                                :rules="currencyFieldRules"
                                :label="$t('common.amount')"
                            ></v-currency-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="4">
                            <v-subheader class="subtitle-1">{{
                                $t("rental-reference-form.lease_type")
                            }}</v-subheader>
                        </v-col>
                        <v-col cols="11" offset="1" md="8" offset-md="0">
                            <v-radio-group
                                row
                                v-model="form.lease_type"
                                :rules="radioButtonsRules"
                            >
                                <v-radio
                                    value="monthToMonth"
                                    :label="$t('rental-reference-form.lease_type_values.monthToMonth')"
                                ></v-radio>
                                <v-radio
                                    value="oneYear"
                                    :label="$t('rental-reference-form.lease_type_values.oneYear')"
                                ></v-radio>
                                <v-radio
                                    value="multiYear"
                                    :label="$t('rental-reference-form.lease_type_values.multiYear')"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="4">
                            <v-subheader class="subtitle-1">{{
                                $t("rental-reference-form.are_dates_correct")
                            }}</v-subheader>
                        </v-col>
                        <v-col cols="11" offset="1" md="8" offset-md="0">
                            <v-row dense>
                                <v-col cols="12" lg="6">
                                    <v-menu
                                        ref="menu1"
                                        v-model="menu1"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :disabled="
                                                    form.are_dates_correct === null ||
                                                    form.are_dates_correct === true
                                                "
                                                autocomplete="off"
                                                :label="$t('common.move_in')"
                                                v-model="form.move_in"
                                                prepend-icon="mdi-calendar"
                                                v-bind="attrs"
                                                @blur="date1 = parseDate(form.move_in)"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            no-title
                                            v-model="date1"
                                            @input="menu1 = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" lg="6">
                                    <v-menu
                                        ref="menu2"
                                        v-model="menu2"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :disabled="
                                                    form.are_dates_correct === null ||
                                                    form.are_dates_correct === true
                                                "
                                                autocomplete="off"
                                                :label="$t('common.move_out')"
                                                v-model="form.move_out"
                                                prepend-icon="mdi-calendar"
                                                v-bind="attrs"
                                                @blur="date2 = parseDate(form.move_out)"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            no-title
                                            v-model="date2"
                                            @input="menu2 = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="12">
                                    <v-radio-group
                                        :rules="dateRules"
                                        v-model="form.are_dates_correct"
                                        row
                                    >
                                        <v-radio
                                            :label="$t('rental-reference-form.are_dates_correct_values.yes')"
                                            v-bind:value="true"
                                        ></v-radio>
                                        <v-radio
                                            :label="$t('rental-reference-form.are_dates_correct_values.no')"
                                            v-bind:value="false"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="4">
                            <v-subheader class="subtitle-1">{{
                                $t("rental-reference-form.gave_notice")
                            }}</v-subheader>
                        </v-col>
                        <v-col cols="11" offset="1" md="8" offset-md="0">
                            <v-radio-group
                                :rules="radioButtonsRules"
                                v-model="form.gave_notice"
                                row
                            >
                                <v-radio :label="$t('common.yes')" v-bind:value="true"></v-radio>
                                <v-radio :label="$t('common.no')" v-bind:value="false"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="form.gave_notice === false">
                        <v-col cols="12" md="4">
                            <v-subheader class="subtitle-1">{{
                                $t("rental-reference-form.reference_released")
                            }}</v-subheader>
                        </v-col>
                        <v-col cols="11" offset="1" md="8" offset-md="0">
                            <v-radio-group
                                :rules="radioButtonsRules"
                                v-model="form.reference_released"
                                row
                            >
                                <v-radio :label="$t('common.yes')" v-bind:value="true"></v-radio>
                                <v-radio :label="$t('common.no')" v-bind:value="false"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <div v-if="contact_state === 'OR'">
                        <v-row dense>
                            <v-col cols="12" md="4">
                                <v-subheader class="subtitle-1">{{
                                    $t("rental-reference-form.has_late_payment")
                                }}</v-subheader>
                            </v-col>
                            <v-col cols="11" offset="1" md="8" offset-md="0">
                                <v-row dense>
                                    <v-col cols="12" lg="3">
                                        <v-radio-group
                                            :rules="radioButtonsRules"
                                            v-model="form.has_late_payment"
                                            row
                                        >
                                            <v-radio
                                                :label="$t('common.yes')"
                                                v-bind:value="true"
                                            ></v-radio>
                                            <v-radio
                                                :label="$t('common.no')"
                                                v-bind:value="false"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <v-col v-if="form.has_late_payment" cols="12" lg="9">
                                        <v-text-field
                                            type="number"
                                            :rules="textFieldRules"
                                            :disabled="!form.has_late_payment === true"
                                            v-model="form.late_payment_count"
                                            :label="$t('common.how_many')"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="12" md="4">
                                <v-subheader class="subtitle-1">{{
                                    $t("rental-reference-form.has_three_day_notice")
                                }}</v-subheader>
                            </v-col>
                            <v-col cols="11" offset="1" md="8" offset-md="0">
                                <v-row dense>
                                    <v-col cols="12" lg="3">
                                        <v-radio-group
                                            :rules="radioButtonsRules"
                                            v-model="form.has_three_day_notice"
                                            row
                                        >
                                            <v-radio
                                                :label="$t('common.yes')"
                                                v-bind:value="true"
                                            ></v-radio>
                                            <v-radio
                                                :label="$t('common.no')"
                                                v-bind:value="false"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <v-col v-if="form.has_three_day_notice" cols="12" lg="9">
                                        <v-text-field
                                            type="number"
                                            :rules="textFieldRules"
                                            :disabled="!form.has_three_day_notice === true"
                                            v-model="form.three_day_notice_count"
                                            :label="$t('common.how_many')"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="12" md="4">
                                <v-subheader class="subtitle-1">{{
                                    $t("rental-reference-form.has_nsf_check")
                                }}</v-subheader>
                            </v-col>
                            <v-col cols="11" offset="1" md="8" offset-md="0">
                                <v-row dense>
                                    <v-col cols="12" lg="3">
                                        <v-radio-group
                                            :rules="radioButtonsRules"
                                            v-model="form.has_nsf_check"
                                            row
                                        >
                                            <v-radio
                                                :label="$t('common.yes')"
                                                v-bind:value="true"
                                            ></v-radio>
                                            <v-radio
                                                :label="$t('common.no')"
                                                v-bind:value="false"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <v-col v-if="form.has_nsf_check" cols="12" lg="9">
                                        <v-text-field
                                            type="number"
                                            :rules="textFieldRules"
                                            :disabled="!form.has_nsf_check === true"
                                            v-model="form.nsf_check_count"
                                            :label="$t('common.how_many')"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                    <v-row dense>
                        <v-col cols="12" md="4">
                            <v-subheader class="subtitle-1">{{
                                $t("rental-reference-form.owes_rent_amount")
                            }}</v-subheader>
                        </v-col>
                        <v-col cols="11" offset="1" md="8" offset-md="0">
                            <v-currency-field
                                type="number"
                                :rules="textFieldRules"
                                v-model="form.owes_rent_amount"
                                :label="$t('common.amount')"
                                prefix="$"
                            ></v-currency-field>
                        </v-col>
                    </v-row>
                    <div v-if="show_covid_fields">
                        <v-row dense>
                            <v-col cols="12" md="4">
                                <v-subheader class="subtitle-1">{{
                                    $t("rental-reference-form.covid_deferred_amount")
                                }}</v-subheader>
                            </v-col>
                            <v-col cols="11" offset="1" md="8" offset-md="0">
                                <v-row dense>
                                    <v-col>
                                        <v-currency-field
                                            type="number"
                                            :rules="textFieldRules"
                                            v-model="form.covid_deferred_amount"
                                            :label="$t('common.amount')"
                                            prefix="$"
                                        ></v-currency-field>
                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col cols="12">
                                        <v-textarea
                                            v-model="form.covid_deferred_comments"
                                            :placeholder="$t('common.explain_here')"
                                            rows="1"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="12" md="4">
                                <v-subheader class="subtitle-1">{{
                                    $t("rental-reference-form.covid_past_due_amount")
                                }}</v-subheader>
                            </v-col>
                            <v-col cols="11" offset="1" md="8" offset-md="0">
                                <v-row dense>
                                    <v-col>
                                        <v-currency-field
                                            type="number"
                                            :rules="textFieldRules"
                                            v-model="form.covid_past_due_amount"
                                            :label="$t('common.amount')"
                                            prefix="$"
                                        ></v-currency-field>
                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col cols="12">
                                        <v-textarea
                                            v-model="form.covid_past_due_comments"
                                            :placeholder="$t('common.explain_here')"
                                            rows="1"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                    <v-row dense>
                        <v-col cols="12" md="4">
                            <v-subheader class="subtitle-1">{{
                                $t("rental-reference-form.has_violation")
                            }}</v-subheader>
                        </v-col>
                        <v-col cols="11" offset="1" md="8" offset-md="0">
                            <v-row dense>
                                <v-col>
                                    <v-radio-group
                                        :rules="radioButtonsRules"
                                        v-model="form.has_violation"
                                        row
                                    >
                                        <v-radio
                                            :label="$t('common.yes')"
                                            v-bind:value="true"
                                        ></v-radio>
                                        <v-radio
                                            :label="$t('common.no')"
                                            v-bind:value="false"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row v-if="form.has_violation" dense>
                                <v-col cols="12">
                                    <v-textarea
                                        v-model="form.violation_comments"
                                        :placeholder="$t('common.explain_here')"
                                        rows="1"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="4">
                            <v-subheader class="subtitle-1">{{
                                $t("rental-reference-form.owes_for_damage")
                            }}</v-subheader>
                        </v-col>
                        <v-col cols="11" offset="1" md="8" offset-md="0">
                            <v-row dense>
                                <v-col>
                                    <v-radio-group
                                        :rules="radioButtonsRules"
                                        v-model="form.owes_for_damage"
                                        row
                                    >
                                        <v-radio
                                            :label="$t('common.yes')"
                                            v-bind:value="true"
                                        ></v-radio>
                                        <v-radio
                                            :label="$t('common.no')"
                                            v-bind:value="false"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row v-if="form.owes_for_damage" dense>
                                <v-col cols="12">
                                    <v-currency-field
                                        type="number"
                                        :rules="currencyFieldRules"
                                        v-model="form.owes_for_damage_amount"
                                        :label="$t('common.amount')"
                                        prefix="$"
                                    ></v-currency-field>
                                </v-col>
                            </v-row>
                            <v-row v-if="form.owes_for_damage" dense>
                                <v-col cols="12">
                                    <v-textarea
                                        v-model="form.owes_for_damage_comments"
                                        :placeholder="$t('common.explain_here')"
                                        rows="1"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="4">
                            <v-subheader class="subtitle-1">{{
                                $t("rental-reference-form.signed_rental_agreement")
                            }}</v-subheader>
                        </v-col>
                        <v-col cols="11" offset="1" md="8" offset-md="0">
                            <v-radio-group
                                :rules="radioButtonsRules"
                                v-model="form.signed_rental_agreement"
                                row
                            >
                                <v-radio :label="$t('common.yes')" v-bind:value="true"></v-radio>
                                <v-radio :label="$t('common.no')" v-bind:value="false"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="4">
                            <v-subheader class="subtitle-1">{{
                                $t("rental-reference-form.has_unauthorized_tenants")
                            }}</v-subheader>
                        </v-col>
                        <v-col cols="11" offset="1" md="8" offset-md="0">
                            <v-radio-group
                                :rules="radioButtonsRules"
                                v-model="form.has_unauthorized_tenants"
                                row
                            >
                                <v-radio :label="$t('common.yes')" v-bind:value="true"></v-radio>
                                <v-radio :label="$t('common.no')" v-bind:value="false"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="4">
                            <v-subheader class="subtitle-1">{{
                                $t("rental-reference-form.has_unauthorized_pets")
                            }}</v-subheader>
                        </v-col>
                        <v-col cols="11" offset="1" md="8" offset-md="0">
                            <v-radio-group
                                :rules="radioButtonsRules"
                                v-model="form.has_unauthorized_pets"
                                row
                            >
                                <v-radio :label="$t('common.yes')" v-bind:value="true"></v-radio>
                                <v-radio :label="$t('common.no')" v-bind:value="false"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="4">
                            <v-subheader class="subtitle-1">{{
                                $t("rental-reference-form.has_pet")
                            }}</v-subheader>
                        </v-col>
                        <v-col cols="11" offset="1" md="8" offset-md="0">
                            <v-radio-group :rules="radioButtonsRules" v-model="form.has_pet" row>
                                <v-radio :label="$t('common.yes')" v-bind:value="true"></v-radio>
                                <v-radio :label="$t('common.no')" v-bind:value="false"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="4">
                            <v-subheader class="subtitle-1">{{
                                $t("rental-reference-form.was_evicted")
                            }}</v-subheader>
                        </v-col>
                        <v-col cols="11" offset="1" md="8" offset-md="0">
                            <v-radio-group
                                :rules="radioButtonsRules"
                                v-model="form.was_evicted"
                                row
                            >
                                <v-radio :label="$t('common.yes')" v-bind:value="true"></v-radio>
                                <v-radio :label="$t('common.no')" v-bind:value="false"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="4">
                            <v-subheader class="subtitle-1">{{
                                $t("rental-reference-form.was_deposit_refunded")
                            }}</v-subheader>
                        </v-col>
                        <v-col cols="11" offset="1" md="8" offset-md="0">
                            <v-radio-group
                                :rules="radioButtonsRules"
                                v-model="form.was_deposit_refunded"
                                row
                            >
                                <v-radio :label="$t('common.yes')" v-bind:value="true"></v-radio>
                                <v-radio :label="$t('common.no')" v-bind:value="false"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="4">
                            <v-subheader class="subtitle-1">{{
                                $t("rental-reference-form.would_rent_to_again")
                            }}</v-subheader>
                        </v-col>
                        <v-col cols="11" offset="1" md="8" offset-md="0">
                            <v-radio-group
                                :rules="radioButtonsRules"
                                v-model="form.would_rent_to_again"
                                row
                            >
                                <v-radio :label="$t('common.yes')" value="true"></v-radio>
                                <v-radio :label="$t('common.no')" value="false"></v-radio>
                                <v-radio :label="$t('common.na')" value="N/A"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12">
                            <v-textarea
                                v-model="form.comments"
                                outlined
                                class="ml-4"
                                :label="$t('common.additional_comments')"
                                rows="3"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <div v-if="!valid" class="red--text mr-4">
                            {{ $t("common.fix_form_errors") }}
                        </div>
                        <v-btn color="primary" @click="submitForm" :disabled="!valid">
                            <v-icon class="mr-2">mdi-check-circle</v-icon
                            >{{ $t("common.submit") }}
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card-text>
        </div>

    </div>
</template>

<script>
export default {
    name: "rental-reference-form",
    components: {
        LocaleSwitcher: () => import("@components/global/utils/LocaleSwitcher")
    },
    props: {
        reference: {
            type: Object,
            required: true,
        },
        url: {
            type: String,
            required: true,
        },
    },
    data(vr) {
        return {
            // API data
            is_complete: this.reference.is_complete,
            applicant_name: this.reference.applicant.name,
            applicant_birthdate: this.reference.applicant.birthdate,
            contact_name: this.reference.reference.contact.name,
            contact_state: this.reference.reference.contact.state,
            listed_rent_amount: this.reference.reference.address.monthly_payment,
            listed_move_in: this.reference.reference.address.move_in_date,
            listed_move_out: this.reference.reference.address.move_out_date,
            show_covid_fields: this.reference.meta.show_covid_fields,
            
            // component logic
            valid: true,
            menu1: false,
            menu2: false,
            date1: this.parseDate(this.reference.reference.address.move_in_date),
            date2: this.parseDate(this.reference.reference.address.move_out_date),
           
           //form rules
            currencyFieldRules: [(v) => v !== "0.00" || vr.$t("common.required_error"), (v) => !!v || vr.$t("common.required_error")],
            radioButtonsRules: [(v) => v !== null || vr.$t("common.select_option_error")],
            textFieldRules: [(v) => !!v || vr.$t("common.required_error")],
            dateRules: [(v) => v !== null || vr.$t("common.required_error")],

            //form data
            form: {
                rent_amount: null,
                lease_type: null,
                are_dates_correct: null,
                move_in: this.reference.reference.address.move_in_date,
                move_out: this.reference.reference.address.move_out_date,
                gave_notice: null,
                reference_released: null,
                has_late_payment: null,
                late_payment_count: null,
                has_three_day_notice: null,
                three_day_notice_count: null,
                has_nsf_check: null,
                nsf_check_count: null,
                owes_rent_amount: null,
                covid_deferred_amount: null,
                covid_deferred_comments: null,
                covid_past_due_amount: null,
                covid_past_due_comments: null,
                has_violation: null,
                violation_comments: null,
                owes_for_damage: null,
                owes_for_damage_amount: null,
                owes_for_damage_comments: null,
                signed_rental_agreement: null,
                has_unauthorized_tenants: null,
                has_unauthorized_pets: null,
                has_pet: null,
                was_evicted: null,
                was_deposit_refunded: null,
                would_rent_to_again: null,
                comments: null,
            },
        };
    },
    computed: {
        computedDateFormatted1() {
            return this.formatDate(this.date1);
        },
        computedDateFormatted2() {
            return this.formatDate(this.date2);
        },
    },
    watch: {
        date1() {
            this.form.move_in = this.formatDate(this.date1);
        },
        date2() {
            this.form.move_out = this.formatDate(this.date2);
        },
    },
    methods: {
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${month}/${day}/${year}`;
        },
        parseDate(date) {
            if (!date) return null;

            const [month, day, year] = date.split("/");
            return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
        },
        submitForm() {
            const isValid = this.$refs.form.validate();
            if (isValid) {
                this.$emit("submit", { data: this.form, url: this.url });
            }
        },
    },
};
</script>